
import {get,post} from "@/axios";

//用户页面
export function getOrderList(data) {
    return get('/api/transactions/list',data);
}
export function sendReminderEmail(data) {
    return post('/api/transactions/send_template_email',data);
}


//批量指派
export function Batch_assign(data) {
    return post('/api/transactions/batch_assign',data);
}

//设置备注
export function set_remark(data) {
    return post('/api/transactions/set_remark',data);
  }

  //设置邮箱
export function set_email(data) {
    return post('/api/transactions/update_email',data);
}