
import {get,post} from "@/axios";



export function getCardsList(data) {
  return get('/api/cards/list',data);

}
export function addCards(data) {
  return post('/api/cards/add',data);

}
export function deleteCards(data) {
  return post('/api/cards/delete',data);

}

export function editCards(data) {
  return post('/api/cards/edit',data);

}
export function getCardsAll(data) {
  return get('/api/cards/all',data);

}



