<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="24" :lg="16" :xl="20">
          <a-form-model-item label="状态" colon :labelCol='labelcol1' :wrapperCol="wrappercol1" prop="status">
            <a-radio-group v-model="form.status" @change="changeStatus">
              <a-radio-button value="">
                全部
              </a-radio-button>
              <a-radio-button value="2">
                待处理
              </a-radio-button>
              <a-radio-button value="3">
                已发催单邮件
              </a-radio-button>
              <a-radio-button value="7">
                已发确认邮件
              </a-radio-button>
              <a-radio-button value="8">
                已发转卡邮件
              </a-radio-button>

              <a-radio-button value="5" v-if="token.role=='super'">
                完成
              </a-radio-button>
              <a-radio-button value="6" v-if="token.role=='super'">
                弃单
              </a-radio-button>
              <a-radio-button value="9" v-if="token.role=='super'">
                高风险
              </a-radio-button>
              <a-radio-button value="10" v-if="token.role=='super'">
                已发链接邮件
              </a-radio-button>

            </a-radio-group>
          </a-form-model-item>
        </a-col>


        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="domain">
            <a-input :value="form.domain" placeholder="请输入域名..."
              @input="changeValue($event.target.value,'obj','form','domain')" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="邮箱" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="email">
            <a-textarea :auto-size="{ minRows: 1,}" :value="form.email" placeholder="请输入邮箱..."
              @input="changeValue($event.target.value,'obj','form','email')" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="订单ID" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="order_id">
            <a-input :value="form.order_id" placeholder="请输入订单ID..."
              @input="changeValue($event.target.value,'obj','form','order_id')" />
          </a-form-model-item>
        </a-col>


        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>

    <div class="mainContent"
      style="display: flex;justify-content: flex-end;position: sticky;top: 64px;z-index: 100;border: 1px solid #ebebeb;">
      <!-- 这里不跟后面按钮一样嵌套一层的话，控制显隐时会明显比较慢 -->
      <a-popconfirm disabled>
        <a-button type="primary" :disabled="!selectedRowKeys.length > 0" v-if="token.role=='super'"
          @click="onBatchEditStatus" style="margin-right:12px">
          批量设置状态
        </a-button>
      </a-popconfirm>

      <a-popconfirm disabled>
        <a-button type="primary" :disabled="!selectedRowKeys.length > 0" v-if="token.role=='super'" @click="onAssign"
          style="margin-right:12px">
          批量派单
        </a-button>
      </a-popconfirm>


      <a-popconfirm :title="'已选择'+selectedRowKeys.length+'条订单，是否发送邮件信息?'" style="margin-right:12px"
        :disabled="!selectedRowKeys.length > 0" @confirm="() => onSending()">
        <a-button type="primary" :disabled="!selectedRowKeys.length > 0">
          发送催单邮件
        </a-button>
      </a-popconfirm>

      <a-popconfirm :title="'已选择'+selectedRowKeys.length+'条订单，是否发送邮件信息?'" style="margin-right:12px"
        :disabled="!selectedRowKeys.length > 0" @confirm="() => saveSending('confirm')">
        <a-button class="greenBtn" type="primary" :disabled="!selectedRowKeys.length > 0">
          发送确认邮件
        </a-button>
      </a-popconfirm>

      <a-popconfirm :title="'已选择'+selectedRowKeys.length+'条订单，是否发送邮件信息?'" :disabled="!selectedRowKeys.length > 0"
        @confirm="() => saveSending('transfer_card')">
        <a-button type="primary" class="yellowBtn" :disabled="!selectedRowKeys.length > 0">
          发送转卡邮件
        </a-button>
      </a-popconfirm>


    </div>

    <div class="mainContent">

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start"
              :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table :size="tableSize" :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns"
        :row-key="record => record.id" :data-source="data" :pagination="pagination" :loading="loading" bordered
        @change="handleTableChange" :customRow="customRow" :rowClassName="record => {
          if (record.id ==selectId) return 'clickRowStyle';
        }">
        <!-- 订单id -->
        <template slot="order_id" slot-scope="data,record">
          <div style="display:flex;align-items: center;justify-content: center;">
            {{record.order_id}}
            <a-tooltip placement="topLeft" title="该订单没有历史邮件" v-if="record.history.length==0">
              <a-icon type="exclamation-circle" style="margin-left:6px;font-size: 12px;color: #e08e29;" />
            </a-tooltip>

          </div>
        </template>
        <!-- 状态 -->
        <template slot="status" slot-scope="data,record">
          <a-tag :color="statusColor[record.status]" style="margin-right:0">
            {{record.status|statusFilter}}
          </a-tag>
        </template>

        <!-- 客户邮箱 -->
        <template slot="customer_email" slot-scope="data,record">
          <div v-if="record.editEmail"
            style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;align-items: center;">
            <a-textarea style="" :auto-size="{maxRows: 4 }" placeholder="" v-model="record.customer_email" />

            <a-button size="small" class="greenBtn" type="primary" :loading="record.iconLoading"
              @click="onSaveEmail(record)">保存</a-button>
          </div>
          <div v-else="!record.editEmail"
            style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;align-items: center;">
            <p style="margin-bottom:0;width:100%">{{record.customer_email}}</p>
            <a-button size="small" type="primary" @click="onEditEmail(record)">编辑</a-button>
          </div>
        </template>

        <!-- 客户备注 -->
        <template slot="customer_remark" slot-scope="data,record">
          <span>{{data}}</span>
        </template>
        <!-- <template slot="users_remark" slot-scope="data,record">
        <div v-if="record.editRemark" style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;align-items: center;">
          <a-textarea style="" :auto-size="{maxRows: 4 }" placeholder="" v-model="record.users_remark" />

          <a-button size="small" class="greenBtn" type="primary" :loading="record.iconLoading" @click="onSaveRemark(record)">保存</a-button>
        </div>
        <div v-else="!record.editRemark" style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;align-items: center;">
          <p style="margin-bottom:0;width:100%">{{record.users_remark}}</p>
          <a-button size="small" type="primary" @click="onEditRemark(record)" >编辑</a-button>
        </div>
      </template> -->



        <!-- 客服 -->
        <template slot="username" slot-scope="data,record">
          {{record.username||'未指定'}}
        </template>
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

          <span class="narrow">
            <!-- <a-button size="small" type="primary"  @click="seeEmail(data)" :disabled="data.history.length == 0">邮件详情</a-button> -->
            <a-button size="small" type="primary" @click="onEditStatus(data)">设置状态</a-button>
            <!-- <a-popconfirm title="是否删除此信息?" @confirm="() => onDelete(data)">
                  <a-button size="small" type="danger">删除</a-button type="primary" >
                </a-popconfirm> -->
          </span>

        </template>
      </a-table>
    </div>

    <!-- 发送催单邮件 -->
    <a-modal :maskClosable="false" title="发送催单邮件" :visible="editModalVisible" :confirm-loading="confirmLoading"
      @ok="saveSending('remind')" @cancel="cancelSending">
      <!-- 编辑 -->
      <a-form-model ref="sending" :model="sendingForm" :rules="sendingRules" :label-col="{span:7}"
        :wrapper-col="{span:17}">

        <a-form-model-item label="卡号备注" prop="card_id">
          <a-select v-model="sendingForm.card_id" placeholder="请选择卡号备注..." :options='cardsData'>
          </a-select>
        </a-form-model-item>


      </a-form-model>
    </a-modal>

    <!-- 设置状态 -->
    <a-modal title="设置状态" :visible="editStatusModalVisible" :confirm-loading="editStatusConfirmLoading" @ok="saveStatus"
      @cancel="cancelStatus">
      <a-form-model ref="editStatus" :model="editStatusForm" :rules="editAssignFormRules" :label-col="{span:7}"
        :wrapper-col="{span:17}">


        <a-form-model-item label="状态" prop="status">
          <a-select v-model="editStatusForm.status" placeholder="请选择状态..." :options="status">

          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 批量分配客服 -->
    <a-modal title="批量分配客服" :visible="editAssignModalVisible" :confirm-loading="editAssignConfirmLoading"
      @ok="saveAssign" @cancel="cancelAssign">
      <a-form-model ref="editAssign" :model="editAssignForm" :label-col="{span:7}" :wrapper-col="{span:17}">
        <a-form-model-item label="客服" prop="service_id">
          <a-select v-model="editAssignForm.service_id" placeholder="请选择客服..." :options='userList'>

          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 邮件详情 -->
    <a-drawer title="邮件详情" placement="right" width="800px" :visible="emailVisible" @close="onCloseEmail">
      <a-descriptions title="" bordered :column="{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }">
        <a-descriptions-item label="客户名称">
          <div v-if="emailData.length>0">{{emailData[(emailData.length-1)].name}}</div>
        </a-descriptions-item>
        <a-descriptions-item label="客户邮箱">
          <div v-if="emailData.length>0">{{emailData[(emailData.length-1)].sender_email}}</div>
        </a-descriptions-item>

        <a-descriptions-item label="最新邮件">
          <div style="height: 700px;overflow: auto;">
            <a-collapse v-if="emailData.length>0" :bordered="false">
              <a-collapse-panel :key="index" v-for="(item,index) in emailData" style="margin:4px 0;background:#fff;"
                :showArrow="false" :style="{ background:item.email_type=='received'?'#fff6dd73':'#ccd2ff30'}">
                <div slot="header" style="display: flex;justify-content: space-between;color: rgba(0, 0, 0, 0.65);">

                  <div>{{item.name}}<br>
                    <p style="margin:6px 0 0 0px">{{item.email_subject}}</p>
                  </div>
                  <p style="margin-left: 12px;">{{item.email_time}}</p>

                </div>
                <div style="padding:12px;display: block;background:#fff">
                  <a-dropdown v-if="item.attachment_url.length">
                    <a class="ant-dropdown-link" style='margin-bottom: 12px;display: block;'>
                      下载附件 <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item v-for="(i,z) in item.attachment_url" :key="z">
                        <a :href="i" target='_blank'>{{i}}</a>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                  <div v-html="item.message"></div>
                </div>

              </a-collapse-panel>

            </a-collapse>
          </div>


        </a-descriptions-item>


      </a-descriptions>

    </a-drawer>
  </div>
</template>
<script>
  import { getOrderList, sendReminderEmail, Batch_assign, set_remark, set_email, } from "@/axios/order.js"
  import { debounce } from '@/static/js/public';
  import { set_status } from "@/axios/home.js"
  import { getCardsAll } from "@/axios/cards.js"
  import { getAllUser } from "@/axios/user.js"
  import { getCookie } from '@/static/js/cookie';
  import { myMixin } from "@/static/js/mixin.js"
  import moment from 'moment';
  const columns = [//列描述
    {
      align: 'center',
      title: 'ID',
      dataIndex: 'id',
    },
    {
      align: 'center',
      title: '订单ID',
      dataIndex: 'order_id',
      scopedSlots: { customRender: 'order_id', },
    },
    {
      align: 'center',
      title: '网站域名',
      dataIndex: 'site_url',
    },
    {
      align: 'center',
      title: '客户名称',
      dataIndex: 'customer_name',
      width: 200,
    },
    {
      align: 'center',
      title: '客户邮箱',
      dataIndex: 'customer_email',
      scopedSlots: { customRender: 'customer_email', },
      width: 200,
      sorter: (a, b) => {
        var stringA = a.customer_email.toUpperCase(); // ignore upper and lowercase
        var stringB = b.customer_email.toUpperCase(); // ignore upper and lowercase
        if (stringA < stringB) {
          return -1;
        }
        if (stringA > stringB) {
          return 1;
        }
        // names must be equal
        return 0;
      },
    },

    {
      align: 'center',
      title: '订单IP',
      dataIndex: 'customer_ip',
      width: 200,
    },


    {
      align: 'center',
      title: '客户备注',
      dataIndex: 'customer_remark',
      scopedSlots: { customRender: 'customer_remark', },
      width: 200,
    },
    {
      align: 'center',
      title: '订单金额',
      dataIndex: 'order_price',
    },
    {
      align: 'center',
      title: '订单状态',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status', },
    },
    {
      align: 'center',
      title: '订单时间',
      dataIndex: 'order_time',
    },
    {
      align: 'center',
      title: '客服名称',
      dataIndex: 'username',
      scopedSlots: { customRender: 'username', },
    },
    {
      align: 'center',
      title: '操作',
      // dataIndex: 'status',
      scopedSlots: { customRender: '操作', },
    },

  ];
  export default {
    filters: {

      statusFilter(status) {
        const statusMap = {

          "2": '待处理',
          "3": '已发催单邮件',
          "7": '已发确认邮件',
          "8": '已发转卡邮件',
          "5": '完成',
          "6": '弃单',
          '9': '高风险',
          '10': '已发链接邮件'

        }

        return statusMap[status]

      }

    },
    mixins: [myMixin],
    data() {

      return {



        form: {
          domain: '',
          page_size: 50,
          page: 1,
          email: '',
          order_id: '',
          status: '2',

        },
        labelcol1: {//搜索框尺寸
          md: { span: 3 },
          sm: { span: 3 }
        },
        wrappercol1: {
          md: { span: 20 },
          sm: { span: 20 }
        },
        columns,//列描述

        pagination: {
          showQuickJumper: true,//是否快速跳转某页
          showSizeChanger: true,//是否可以改变条数
          pageSizeOptions: ['10', '20', '50', '100', '200'],
          pageSize: 50,
          showTotal: (total, range) => `当前显示第${range[0]}-${range[1]}，总条数：${total} `
        },//分页器

        cardsData: [],

        confirmLoading: false,//编辑模态框
        editModalVisible: false,
        sendingForm: {
          card_id: '',
        },
        sendingRules: {
          card_id: [
            { required: true, message: '请选择卡号', trigger: 'change' },
          ],


        },

        editAssignModalVisible: false,//批量分配
        editAssignConfirmLoading: false,
        editAssignForm: {
          service_id: '',
          transaction_ids: '',
        },
        userList: [],

        statusColor: {

          "2": 'orange',
          "3": 'pink',
          "4": 'blue',
          "5": 'green',
          "6": 'red',
          "7": 'cyan',
          "8": 'purple',
          '9': '#ff0000',
          '10': 'geekblue'
        },
        status: [

          {
            value: '2',
            label: '待处理'
          },
          {
            value: '3',
            label: '已发催单邮件'
          },
          {
            value: '7',
            label: '已发确认邮件'
          },
          {
            value: '8',
            label: '已发转卡邮件'
          },



        ],
        token: {},

        emailVisible: false,//邮件详情
        emailData: [],
        replyEmailData: '',

        editStatusModalVisible: false,//设置状态
        editStatusConfirmLoading: false,
        editStatusForm: {
          status: '',
          transaction_id: '',
        },
        editAssignFormRules: {
          status: [
            { required: true, message: '请选择状态', trigger: 'change' },
          ],
        },

        selectId: '',//选中行的ID，用来设置背景色
        changeValue: null,
        sorter: undefined,//记录原先的sorter值
      }

    },
    mounted() {
      let that = this

      getCardsAll().then((data) => {
        this.cardsData = data.data.map((item) => {
          return {
            value: item.id,
            label: item.remark
          }
        })
      });
      this.token = JSON.parse(getCookie("token"))
      if (this.token.role == 'super') {

        getAllUser({ show_super: 0 }).then((data) => {
          this.userList = data.data.map((item) => {
            return {
              value: item.id,
              label: item.username
            }
          })
        });

        this.status = [...this.status,
        {
          value: '5',
          label: '完成'
        },
        {
          value: '6',
          label: '弃单'
        },
        {
          value: '9',
          label: '高风险'
        },
          {
            value: '10',
            label: '已发链接邮件'
          },
        ]

      }
      this.fetch();
      this.changeValue = debounce(this.editInput)
    },
    methods: {
      editInput(value, obj, ...keys) {
        if (keys.length == 1) {
          if (obj == 'obj') {
            this[keys[0]] = value
          } else {
            obj[keys[0]] = value
          }
        } else {
          this.editInput(value, this[keys[0]], ...keys.slice(1))
        }

      },
      customRow(data) {
        return {
          on: {
            dblclick: () => {
              window.getSelection ? window.getSelection().removeAllRanges() : document.selection.empty()
              let no = ['button', 'textarea', "input"];

              if (no.includes(event.target.localName) || data.history.length == 0) {
                return
              } else {
                this.emailData = data.history;
                this.selectId = data.id
                this.emailVisible = true
              }

            },        // 点击表头行
          }
        };

      },

      changeStatus(data) {
        this.form.page = 1;
        this.pagination.current = 1;
        this.fetch();
      },
      async fetch(params = {}) {
        this.loading = true;
        let data = await getOrderList({ ...params, ...this.form })
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;

        this.selectedRowKeys = [];
        this.data = data.data.list;
        this.pagination = pagination;
        console.log(data, this.pagination, 'data')

        this.loading = false;
        this.selectId = '';

      },



      //删除数据
      async onDelete(data) {

        let resule = await deleteEmail({ config_id: data.id });
        if (resule.code == 200) {
          this.$message.success(resule.msg);
          this.fetch()
        } else {
          this.$message.error(resule.msg);
        }
      },

      onSending() {
        this.editModalVisible = true

      },

      //催单方式
      async saveSending(type) {
        let resule;
        if (type == 'confirm' || type == 'transfer_card') {
          resule = await sendReminderEmail({ ids: this.selectedRowKeys.join(','), template_type: type, card_id: '' });
          if (resule.code == 200) {
            this.$message.success(resule.msg);
            // this.selectedRowKeys=[];
          } else {
            this.$message.error(resule.msg);
          }
        } else if (type == 'remind') {
          this.$refs.sending.validate(async valid => {
            if (valid) {
              this.confirmLoading = true;
              resule = await sendReminderEmail({ ids: this.selectedRowKeys.join(','), template_type: type, card_id: this.sendingForm.card_id });

              if (resule.code == 200) {
                this.$message.success(resule.msg);
                this.editModalVisible = false;
                this.$refs.sending.resetFields();
                this.selectedRowKeys = [];
                this.fetch();
              } else {
                this.$message.error(resule.msg);
              }
              this.confirmLoading = false;
            } else {
              return false;
            }
          });
        }


      },

      cancelSending() {
        this.$refs.sending.resetFields();
        this.editModalVisible = false;
      },

      // 批量分配

      onAssign(data) {
        // this.editAssignForm.status=data.status
        let d = [];
        this.selectedRowKeys.map((item) => {
          this.data.map((i) => {
            if (i.id == item) d.push(i.transaction_id)
          })

        })
        this.editAssignForm.transaction_ids = d.join(',')
        this.editAssignModalVisible = true
      },
      saveAssign() {
        this.$refs.editAssign.validate(async valid => {
          if (valid) {
            this.editAssignConfirmLoading = true;
            let resule = await Batch_assign({ ...this.editAssignForm });
            if (resule.code == 200) {
              this.$message.success(resule.msg);
              this.selectedRowKeys = [];
              this.editAssignModalVisible = false;
              this.editAssignConfirmLoading = false;
              this.$refs.editAssign.resetFields();
              this.fetch();
            } else {
              // this.$message.error(resule.msg);
              this.editAssignConfirmLoading = false;
            }

          } else {
            return false;
          }
        });

      },
      cancelAssign() {
        this.$refs.editAssign.resetFields();
        this.editAssignModalVisible = false;
      },

      // 更改邮箱
      onEditEmail(data) {
        this.$set(data, 'editEmail', true)

      },

      onSaveEmail(data) {
        this.$set(data, 'emailIconLoading', true);
        set_email({ transaction_id: data.transaction_id, customer_email: data.customer_email }).then((resule) => {
          if (resule.code == 200) {
            // this.fetch();
            data.editEmail = false;

            this.$message.success(resule.msg);
          } else {
            this.$message.error(resule.msg);
          }
          data.emailIconLoading = false;
        });
      },

      // 更改备注
      // onEditRemark(data){
      //   this.$set(data, 'editRemark', true)

      // },

      // onSaveRemark(data){
      //   this.$set(data, 'remarkIconLoading', true)
      //   set_remark({transaction_id:data.transaction_id,remark:data.users_remark}).then((resule)=>{
      //     if(resule.code==200){
      //       console.log(resule)
      //       // this.fetch();
      //       data.editRemark=false;
      //       this.$message.success(resule.msg);
      //     }else{
      //       this.$message.error(resule.msg);
      //     }
      //     data.remarkIconLoading=false;
      //   });
      // },

      onCloseEmail() {
        this.emailVisible = false
      },

      //修改状态
      onEditStatus(data) {
        this.editStatusForm.status = data.order_status;
        this.editStatusForm.transaction_id = data.transaction_id;
        this.editStatusModalVisible = true;
      },
      onBatchEditStatus() {
        let d = [];
        this.selectedRowKeys.map((item) => {
          this.data.map((i) => {
            if (i.id == item) d.push(i.transaction_id)
          })

        })
        this.editStatusForm.transaction_id = d.join(',')

        this.editStatusForm.status = '';
        this.editStatusModalVisible = true;
      },
      saveStatus() {
        this.$refs.editStatus.validate(async valid => {
          if (valid) {
            this.editStatusConfirmLoading = true;
            let resule = await set_status({ ...this.editStatusForm });
            if (resule.code == 200) {
              this.$message.success(resule.msg);
              this.editStatusModalVisible = false;
              this.editStatusConfirmLoading = false;
              this.$refs.editStatus.resetFields();
              this.fetch();
            } else {
              this.$message.error(resule.msg);
              this.editStatusConfirmLoading = false;
            }

          } else {
            return false;
          }
        });


      },
      cancelStatus() {
        this.$refs.editStatus.resetFields();
        this.editStatusModalVisible = false;
      },
      handleTableChange(pagination, filters, sorter) {
        //pagination:分页信息，filters：筛选信息，sorter：排序信息
        //如果不等于空，说明触发了排序，如果值没变，说明是上一轮触发排序，不发起请求
        if (sorter.order != {} && sorter.order != this.sorter) {
          // console.log()
          this.sorter = sorter.order
          return
        } else {
          this.pagination = { ...pagination };
          this.form.page_size = pagination.pageSize
          this.form.page = pagination.current
          console.log(this.pagination, pagination, 'pagination')

          this.fetch({

          });
        }


      },
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/static/css/index.scss";

  .ant-table td {
    white-space: nowrap;
  }

  ::v-deep .ant-table-bordered .ant-table-thead>tr>th {
    word-break: inherit;
  }

  ::v-deep .ant-modal-body {

    max-height: 600px;
    overflow-x: auto;
  }

  .lyq_top_border p {
    margin-bottom: 4px;
  }

  .lyq_top_border {
    border-top: 1px solid #e4e4e4;
  }

  ::v-deep .ant-descriptions-item-label {
    width: 120px;
    vertical-align: baseline;
  }

  ::v-deep .ant-collapse-content p,
  .ant-collapse-header p {
    margin-bottom: 0px;
  }
</style>